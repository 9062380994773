<template>
  <footer class="base-footer">
    <section class="container xl:py-16 py-8 2xl:px-0 px-8">
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-8 h-full gap-y-8">
        <div class="xl:col-span-2 footer-col gap-4 pr-2">
          <BaseCompanyLogo
            img-class="max-w-48 max-h-20 h-auto"
            title="Company Logo"
            :as-anchor="true"
          />
        </div>
        <div
          v-for="(col, idx) in footerCols"
          :key="idx"
          class="xl:col-span-2 footer-col"
        >
          <div>
            <div class="footer-title">
              {{ col?.title }}
            </div>
            <ul v-if="Boolean(col.items?.length)" class="footer-col__list">
              <li v-for="(row, idx) in col.items" :key="idx">
                <BaseLink
                  v-if="row.internal_link?.page"
                  :to="localePath(row?.internal_link?.page?.url_path)"
                >
                  {{ row.link_text }}
                </BaseLink>
                <BaseLink
                  v-else
                  :data-external="row.external_link"
                  :to="localePath(row.external_link)"
                  target="_blank"
                >
                  {{ row.link_text }}
                </BaseLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-8 mt-8">
        <div class="col-span-2" />
        <div class="xl:col-span-2">
          <div class="footer-social self-center">
            {{ socialNetworks?.title }}
            <ul>
              <li
                v-for="socialNetwork in socialNetworks.items"
                :key="socialNetwork.logo.id"
              >
                <NuxtLink
                  :to="socialNetwork.link"
                  rel="noopener"
                  target="_blank"
                  external
                >
                  <img
                    :src="socialNetwork?.logo?.original?.src"
                    :alt="socialNetwork?.logo?.title"
                    loading="lazy"
                    class="w-8 hover:brightness-[10] transition-all duration-300 ease-in-out"
                  >
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-8 mt-12">
        <div class="col-span-2" />
        <div class="xl:col-span-2">
          <!-- <div class="tag-cloud text-sm text-brand-black/50">
            Coches en suscripción en Barcelona<br>
            Coches en suscripción en Madrid<br>
            Coches en suscripción en Bilbao<br>
            Coches en suscripción en Valencia<br>
            Coches en suscripción en Sevilla<br>
          </div> -->
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-8 mt-8">
        <div class="xl:col-span-2">
          <div>
            <span class="text-xs font-semibold">
              {{ copyrightText || '' }}
            </span>
          </div>
        </div>

        <div class="xl:col-span-2">
          <NuxtLink
            to="https://worldwidemobility.io/"
            class="text-sm"
            target="_blank"
            external
            no-rel
          >
            <span class="text-brand-black/60">
              Powered By
            </span> World Wide Mobility
          </NuxtLink>
        </div>

        <div class="self-end xl:col-span-3">
          <FooterLegalLinks :legal-links="legalLinks" />
        </div>
      </div>
    </section>
  </footer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BaseLink from '~/components/BaseLink.vue';
import BaseCompanyLogo from '~/components/BaseCompanyLogo.server.vue';
import { useLocalePath } from '#imports';
import FooterLegalLinks from './FooterLegalLinks.vue';
import type { FooterValue } from '~/types';

interface Props {
  items: FooterValue
}

const props = defineProps<Props>();
const localePath = useLocalePath();

const footerCols = computed(() => {
  // For design reasons the max should be 3 columns
  return props?.items?.columns?.slice(0, 3) || [];
});

const copyrightText = computed(() => props?.items?.copyright_text || false);
const legalLinks = computed(() => props?.items?.legal_links || []);
const socialNetworks = computed(() => props?.items?.social_networks || []);
</script>

<style scoped>
.base-footer {
  .footer-col {
    @apply grid gap-4;
  }
  .footer-col__list {
      @apply text-base flex flex-col gap-2 items-start mt-4;
    }
  .footer-title {
    @apply font-semibold;
  }

  .footer-social {
    ul {
      @apply flex items-center justify-start gap-x-3 mt-3;
      li > svg {
        @apply w-8 h-8;
      }
    }
  }
}
</style>
